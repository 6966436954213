import React from 'react'
import { useStyletron } from 'baseui'
import { Paragraph2 } from 'baseui/typography'
import { ListItem, ListItemLabel } from 'baseui/list'
import { Check } from 'baseui/icon'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CTA from '../components/RegisterCTA'
import PremiumHero from '../components/PremiumHero'
import SearchLink from '../components/SearchLink'
import BenefitsSlider from '../components/BenefitsSlider'
// import Emoji from '../components/Emoji'
import MultilinkSection from '../components/MultilinkSection'
import {
  FiPlusCircle,
  FiEdit,
  FiExternalLink,
  FiBarChart,
  FiTag,
} from 'react-icons/fi'
import { FcSearch } from 'react-icons/fc'
import Pricing from '../components/Pricing'

export default () => {
  const [css] = useStyletron()

  const cardClass = css({
    height: '100%',
    width: '300px',
    margin: '30px auto',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '15px',

    background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
  })

  const cardTitle = css({
    textAlign: 'center',
    fontSize: '1.5rem',
    color: '#fff',
    padding: '5px',
  })

  const iconClass = css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  })

  const bannerCard = css({
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    borderRadius: '15px',
    padding: '1.5rem',
  })

  const iconCenter = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })

  return (
    <Layout page="premium" hero={<PremiumHero />}>
      <SEO
        title="Create branded WhatsApp links"
        description="Create your own WhatsApp link with branded URL, analytics and control. With Walink Premium increase your brand awareness and get more benefits."
        hreflang={[
          {
            rel: 'alternate',
            hrefLang: 'en',
            href: 'https://create.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'es',
            href: 'https://crear.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'pt',
            href: 'https://criar.wa.link/premium',
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: 'https://create.wa.link/premium',
          },
        ]}
      />
      <div>
        <div style={{ margin: '4rem 0' }}>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '1.8rem',
              marginBottom: '2.5rem',
            }}
            id="search"
          >
            Find a branded WhatsApp link for your business
          </h2>
          <SearchLink />
        </div>
      </div>
      <div style={{ marginBottom: '4rem', marginTop: '4rem' }}>
        <div
          className={css({
            textAlign: 'center',
            marginTop: '3rem',
            marginBottom: '3rem',
          })}
        >
          <h2>Why is Walink Premium ideal for your business?</h2>
          <Paragraph2>
            Find out why our service is the best solution to drive customers to
            your WhatsApp chat.
          </Paragraph2>
        </div>
        <BenefitsSlider />
      </div>
      <div
        className={css({
          textAlign: 'center',
          marginTop: '4rem',
          marginBottom: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '2rem',
        })}
      >
        <h2>Features included in Premium</h2>
        <Paragraph2>
          <strong>Pay just $6 USD per link annually</strong>. Each link includes
          the following features:
        </Paragraph2>
      </div>

      <div
        className={css({
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
        })}
      >
        <div className={cardClass}>
          <div className={iconClass}>
            <FiTag size={45} />
          </div>
          <h3 className={cardTitle}>Branded links</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              wa.link/<strong>YourCompany</strong>
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              wa.link/<strong>YourBrand</strong>
            </ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>
              wa.link/<strong>YourCatalog</strong>
            </ListItemLabel>
          </ListItem>
        </div>
        <div className={cardClass}>
          <div className={iconClass}>
            <FiEdit size={45} />
          </div>
          <h3 className={cardTitle}>Updatable info</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Change the phone number or custom message of your link.
            </ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>
              Create links for your WhatsApp catalog.
            </ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>Change the custom URL.</ListItemLabel>
          </ListItem>
        </div>
        <div className={cardClass}>
          <div className={iconClass}>
            <FiBarChart size={45} />
          </div>
          <h3 className={cardTitle}>Analytics</h3>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>Daily and hourly clicks.</ListItemLabel>
          </ListItem>
          <ListItem artwork={props => <Check {...props} />}>
            <ListItemLabel>Clicks by location.</ListItemLabel>
          </ListItem>
          <ListItem
            overrides={{
              Root: {
                style: {
                  borderRadius: '0 0 15px 15px',
                },
              },
            }}
            artwork={props => <Check {...props} />}
          >
            <ListItemLabel>Source of clicks</ListItemLabel>
          </ListItem>
        </div>
      </div>
      <div className={iconCenter}>
        <FiPlusCircle size="30px" />
      </div>
      <div style={{ marginBottom: '3rem', marginTop: '2rem' }}>
        <div className={bannerCard}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <h3 style={{ margin: 0 }}>Plus... let clients find you!</h3>
            <FcSearch size="30px" style={{ marginLeft: '5px' }} />
          </div>
          <Paragraph2>
            Appear as result of our{' '}
            <strong>
              <a
                href="https://open.wa.link/search"
                target="_blank"
                rel="noopener noreferrer"
              >
                search engine
                <span style={{ marginLeft: '2px' }}>
                  <FiExternalLink size="16" />
                </span>
              </a>
            </strong>
            , so your customers can find your WhatsApp just by knowing your
            business name.
          </Paragraph2>
        </div>
      </div>
      <div
        className={css({
          textAlign: 'center',
          marginTop: '4rem',
          marginBottom: '1rem',
          borderTop: '1px solid rgba(0, 0, 0, 0.1)',
          paddingTop: '2rem',
        })}
      >
        <h3>And more...</h3>
      </div>
      <div style={{ marginBottom: '3rem', marginTop: '2rem' }}>
        <MultilinkSection />
      </div>
      <Pricing />
      <CTA
        description={
          <>
            You are a few clicks away from increasing your sales through
            WhatsApp.
          </>
        }
        title="Are you ready for Premium?"
        currentPage="Premium"
      />
    </Layout>
  )
}
