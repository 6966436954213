import React from 'react'
import { useStyletron } from 'baseui'
import { Button, SHAPE, SIZE } from 'baseui/button'
import { Paragraph3 } from 'baseui/typography'
import Emoji from './Emoji'

const MultilinkDescription = () => {
  const [css] = useStyletron()

  const card = css({
    padding: '1rem',
    margin: '0.6rem 0.5rem',
    minWidth: '280px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    borderRadius: '10px',
  })

  const descriptionButton = css({
    display: 'flex',
    justifyContent: 'center',
  })

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }

  return (
    <div className={card}>
      <div
        className={css({
          textAlign: 'center',
          marginBottom: '2rem',
        })}
      >
        <h2>
          <Emoji symbol={'⭐'} size={25} /> Multilink page:{' '}
        </h2>
      </div>
      <Paragraph3>
        The multilink service enables a web page that group all the Walinks you
        want under a single URL.
      </Paragraph3>
      <Paragraph3>
        Each Premium link will be converted into a button that users can easily
        click. It is the perfect solution when you have multiple WhatsApp lines
        for stores, agents, departments, catalog, services or products.
      </Paragraph3>
      <Paragraph3 style={{ marginBottom: 0 }}>You can customize:</Paragraph3>
      <ul className="multilinkBullets">
        <li>
          <Paragraph3 style={{ marginTop: '0', marginBottom: '0' }}>
            Logo
          </Paragraph3>
        </li>
        <li>
          <Paragraph3>Business Name</Paragraph3>{' '}
        </li>
        <li>
          <Paragraph3>Description</Paragraph3>
        </li>
        <li>
          <Paragraph3>Website</Paragraph3>
        </li>
      </ul>

      <Paragraph3>
        You can also add your own privacy policy for users accept before they
        reach you.
      </Paragraph3>
      <Paragraph3>
        <small>
          * This service is available for free to customers with 2 or more links
          in their subscription plan.
        </small>
      </Paragraph3>
      <div className={descriptionButton}>
        <Button
          onClick={goToApp}
          shape={SHAPE.pill}
          size={SIZE.compact}
          overrides={{
            BaseButton: {
              style: {
                marginTop: '1rem',
                marginBottom: '1rem',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
              },
            },
          }}
        >
          <>
            <Emoji symbol={'🤩'} size={20} /> I want it now
          </>
        </Button>
      </div>
    </div>
  )
}

export default MultilinkDescription
