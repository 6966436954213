import React from 'react'
import { useStyletron } from 'baseui'
import { Button, SHAPE } from 'baseui/button'
import { FiExternalLink } from 'react-icons/fi'
import { RiHome2Line } from 'react-icons/ri'
import { FcShop } from 'react-icons/fc'
import whatsappLogo from '../images/whatsapp-preview.svg'
import Emoji from './Emoji'

const MultilinkPreview = () => {
  const [css] = useStyletron()
  const previewContainer = css({
    width: '100%',
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })

  const previewFrame = css({
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    margin: '1rem auto',
    width: '100%',
    maxWidth: '420px',
  })

  const browserNavContainer = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.3rem 0.5rem',
    borderBottom: '1px solid #ccc',
  })

  const browserNav = css({
    height: '30px',
    width: '90%',
    backgroundColor: '#f4f4f4',
    borderRadius: '20px',
    padding: '2px',
    margin: '0.5rem',
  })

  return (
    <div className={previewContainer}>
      <div className={previewFrame}>
        <div className={browserNavContainer}>
          <RiHome2Line />
          <div className={browserNav}>
            <p style={{ marginTop: 3, marginLeft: 10 }}>
              https://go.wa.link/YourBusiness
            </p>
          </div>
        </div>
        <div className="main">
          <Bio />
          <PreviewButton label="Catalog" />
          <PreviewButton label="Sales" />
          {/* <PreviewButton label="Support" /> */}
          <Info website="walink.io/blog" />
        </div>
        <p className="footer" style={{ marginTop: '0', marginBottom: '0' }}>
          <small>
            Powered by{' '}
            <a
              href="https://create.wa.link/premium"
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              Walink
            </a>
          </small>
        </p>
      </div>
    </div>
  )
}

const PreviewButton = ({ label }) => {
  return (
    <div style={{ margin: '1.3rem 0' }}>
      <Button
        overrides={{
          BaseButton: {
            style: {
              width: '100%',
              backgroundColor: '#00b66c',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
            },
          },
        }}
        shape={SHAPE.pill}
        type="button"
      >
        <span style={{ marginRight: '4px' }}>
          <img src={whatsappLogo} height="14" width="14" alt="wa" />
        </span>
        {label}
      </Button>
    </div>
  )
}

const Bio = () => {
  return (
    <div className="bio">
      <div className="dot">
        <FcShop size={40} />
      </div>
      <h2>Your Business</h2>
      <div className="card">
        <p>
          The best business in the world <Emoji symbol={'🌎'} />
        </p>
      </div>
    </div>
  )
}

const Info = ({ website }) => {
  return (
    <div className="info-container">
      <div style={{ marginBottom: '1rem', wordBreak: 'normal' }}>
        <p style={{ marginTop: '0', marginBottom: '0' }}>
          <small>
            By contacting you accept the{' '}
            <span className="link-active">privacy policy</span> of Your Business
          </small>
        </p>
      </div>
      <>
        <p style={{ marginTop: '0', marginBottom: '0' }}>
          <small>More Info</small>
        </p>
        <a href="https://walink.io/blog" className="link">
          {website}
        </a>
        <span style={{ marginLeft: '4px' }}>
          <FiExternalLink size="16" />
        </span>
      </>
    </div>
  )
}

export default MultilinkPreview
